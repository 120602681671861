<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2021-10-12 15:29:08
 * @LastEditTime: 2021-10-19 13:45:06
 * @Description: 图纸结算查看
 * @Param:
 * @FilePath: \JT_Web_test\src\views\salesBusiness\contractManage\drawingSettlement\monomerView.vue
 -->


<template>
    <div class="monomerView" :class="{'essentialInfoHide': !isEssentialInfo}" v-loading="loading">
        <div class="fomeStyle_dy">
            <table>
                <thead>
                    <tr>
                        <th colspan="4">
                            基本信息表
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>合同编号：</th>
                        <td>
                            <el-input v-model="essentialInfo.contract_number" readonly placeholder="请输入内容"></el-input>
                        </td>
                        <th>合同名称：</th>
                        <td>
                            <el-input v-model="essentialInfo.contract_type_name" readonly placeholder="请输入内容"></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th>工程名称：</th>
                        <td>
                            <el-input v-model="essentialInfo.engineering_name" readonly placeholder="请输入内容"></el-input>
                        </td>
                        <th>客户名称：</th>
                        <td>
                            <el-input v-model="essentialInfo.client_name" readonly placeholder="请输入内容"></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th>单体数量：</th>
                        <td>
                            <el-input v-model="essentialInfo.monomer_count" readonly placeholder="请输入内容"></el-input>
                        </td>
                        <th>当前查看单体：</th>
                        <td>
                            <el-select v-model="currentMonomer" placeholder="请选择" @change="selectMonomer">
                                <el-option
                                    v-for="item in monomerOption"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code"
                                >
                                </el-option>
                            </el-select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="summaryTableBox table-bg">
            <el-table
                border
                height="100%"
                show-summary
                ref="ref_summaryTable"
                :data="summaryTable"
                :summary-method="getSummaries"
                :row-class-name="tableRowClassName"
            >
                <el-table-column
                    label="4#号楼单体图纸结算方量汇总表"
                >
                    <el-table-column
                        label="序号"
                        type="index"
                        width="100"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="floor_name"
                        label="楼层"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="place_name"
                        label="施工部位"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="strength_grade_name"
                        label="标号"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="design_quantity"
                        label="图纸设计算量(m³)"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="product_quantity"
                        label="生产量(m³)"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="than_quantity"
                        label="超量(m³)"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="loss_ratio"
                        label="损失率(%)"
                    >
                    </el-table-column>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'monomer-view',
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            loading: false,
            isEssentialInfo: true,
            essentialInfo: {},
            monomerOption: [],
            currentMonomer: '',
            summaryTable: [],
        };
    },
    computed: {},
    watch: {},
    created() {
        this.initPage();
    },
    mounted() {},
    destroyed() {},
    methods: {
        async initPage() {
            this.loading = true;
            if (this.extr.contract_number) {
                this.essentialInfo = await this.obtainDrawingControl(this.extr.contract_number);
                if (Object.keys(this.essentialInfo.monomerList).length > 0) {
                    for (const k in this.essentialInfo.monomerList) {
                        this.monomerOption.push({
                            name: this.essentialInfo.monomerList[k],
                            code: k,
                        });
                    }
                    this.currentMonomer = this.monomerOption[0].code;
                    this.summaryTable = await this.drawingControlDetails(this.currentMonomer);
                }
            }
            if (this.extr.monomer_number) {
                this.isEssentialInfo = false;
                this.summaryTable = await this.drawingControlDetails(this.extr.monomer_number);
            }
            this.loading = false;
        },
        // 选择单体
        async selectMonomer() {
            this.loading = true;
            this.summaryTable = await this.drawingControlDetails(this.currentMonomer);
            this.loading = false;
        },
        // 合计行
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        }
                        return prev;

                    }, 0);
                } else {
                    sums[index] = '——';
                }
            });
            this.$nextTick(() => {
                this.$refs.ref_summaryTable.doLayout();
            });
            return sums;
        },
        // 行样式
        tableRowClassName({ row }) {
            if (row.loss_ratio && Number(row.loss_ratio) > 1.8 || Number(row.loss_ratio) < -1.8) {
                return 'warning-row';
            }
            return '';
        },
        // 获取图纸结算管控数据
        obtainDrawingControl(contract_number) {
            return this.$axios.get(`/interfaceApi/sale/contract_manager/contract/get_drawing_control/${contract_number}`);
        },
        // 查询单体图纸设计管控详细
        drawingControlDetails(monomer_number) {
            return this.$axios.post(`/interfaceApi/sale/engineering_manager/get_monomer_control/${monomer_number}`);
        },
    },
};
</script>
<style lang="stylus" scoped>
.monomerView
    padding 0.2rem
    height 100%
    &.essentialInfoHide
        .fomeStyle_dy
            display none
        .summaryTableBox
            margin-top 0
            height 100%
    .fomeStyle_dy
        th
            width 2rem
        td
            >>>.el-select
                width 100% !important
    .summaryTableBox
        margin-top 0.2rem
        height calc(100% - 1.7rem)
        >>>.el-table
            th
                border-bottom 1px solid rgba(215,215,215,0.7)
            .el-table__footer-wrapper
                td
                    background-color #F5F2ED
                .cell
                    color #823802
            .warning-row
                .cell
                    color #FF1018
</style>